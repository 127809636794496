import moment from 'moment';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'react-moment';

import { ServiceObjectReportModel } from '../models/CustomerServiceReportModels';

interface DisplaySettings {
    showAll: boolean
}



const CustomerServiceObjectReportsComponent = ({ serviceObjectReports }: { serviceObjectReports: ServiceObjectReportModel[] }) => {

    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ showAll: false });

    const filterServiceReports = (serviceReport: ServiceObjectReportModel, index:number) => {

        if (displaySettings.showAll || moment(serviceReport.createDate).isAfter(moment().subtract(2, 'month')) || index < 5) {
            return true;
        }

        return false;
    }

    const ShowServiceReportAdminUrlLink = ({ serviceReportId }: { serviceReportId: string }) =>
    { 
        const [url, setUrl] = useState<string>();

        useEffect(() => {
            setUrl(`https://pmapp.mkb.se/Prod/Mkb/Pm/?A=MP&l=ac=VisaNod&id=${serviceReportId}`);
        }, [serviceReportId])

        return(
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        Serviceärendet i pm5.
                    </Tooltip>
                }>
                <a href={url} className="clickable-icon" target="pm5gui"><i className="fa-solid fa-up-right-from-square"></i></a>
            </OverlayTrigger>)
    };

    const ShowMoreServiceReports = () => (
        <div className="text-start pb-5">
            <div className="float-start ps-3">
                <span className="fst-italic" style={{ display: (displaySettings.showAll ? 'none' : 'block') }}>Det finns flera aktiva serviceärenden för hyresobjektet</span>
                <span className="fst-italic" style={{ display: (displaySettings.showAll ? 'block' : 'none') }}>Visar alla aktiva serviceärenden</span>
            </div>
            <div className="float-end pe-2">
                <div>
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => setDisplaySettings((prevState) => ({ ...prevState, showAll: true }))} style={{ display: (displaySettings.showAll ? 'none' : 'block') }}>Visa alla serviceärenden för hyresobjektet</button>
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => setDisplaySettings((prevState) => ({ ...prevState, showAll: false }))} style={{ display: (displaySettings.showAll ? 'block' : 'none') }}>Göm äldre serviceärenden för hyresobjektet</button>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {serviceObjectReports?.filter(filterServiceReports)?.map(serviceObjectReport => {

                return (<div className="row ps-4 pb-2 pt-2" key={serviceObjectReport.id}>
                    <div className="row">
                        <div className="col-8 text-start"><ShowServiceReportAdminUrlLink serviceReportId={serviceObjectReport.id} /> [{serviceObjectReport.number}] Status: <span className="fw-bolder">{serviceObjectReport.status}</span></div>
                        <div className="col-4 text-end"><Moment date={serviceObjectReport.createDate} format="YYYY-MM-DD HH:MM:ss" /></div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-start service-object-specification-content">{serviceObjectReport.specification}</div>
                    </div>
                </div>)
            })}
            <div className="row">
                <div className="col-12 text-end">
                    {serviceObjectReports.some(serviceReport => (moment(serviceReport.createDate).isBefore(moment().subtract(2, 'month')))) || serviceObjectReports.length >= 5  ? <ShowMoreServiceReports /> : null}
                </div>
            </div>
        </div>
    );
    
}
export default CustomerServiceObjectReportsComponent;